import request from '@/utils/requestV2'

// 查询平台品牌（分页查询）
export function listPlatformBrands (data) {
  return request({
    url: '/ooh-dw/v1/dw/platform/listPlatformBrands',
    method: 'get',
    params: data
  })
}
// 查询平台品牌
export function listAllBrands () {
  return request({
    url: '/ooh-dw/v1/dw/platform/listAllBrands',
    method: 'get'
  })
}
// 查询行业的基本信息
export function getIndustryById (data) {
  return request({
    url: '/ooh-dw/v1/dw/platform/getIndustryById',
    method: 'get',
    params: data
  })
}
// 查询平台的一级行业
export function listFirstIndustry () {
  return request({
    url: '/ooh-dw/v1/dw/platform/listFirstIndustry',
    method: 'get'
  })
}
// 查询平台的二级行业
export function listSecondIndustry (data) {
  return request({
    url: '/ooh-dw/v1/dw/platform/listSecondIndustry',
    method: 'get',
    params: data
  })
}

// 查询平台行业的广告资质要求
export function getIndustryQualification (data) {
  return request({
    url: '/ooh-dw/v1/dw/platform/getIndustryQualification',
    method: 'get',
    params: data
  })
}

import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 分页查询素材案例
 * @param {Object} data
 * @returns
 */
export function getMaterialLibraryPage (data) {
  return request.post('/ooh-msp/v2/materialLibrary/getMaterialLibraryPage', qs.stringify(data))
}

/**
 * 更新素材库状态
 * @param {Object} data
 * @returns
 */
export function updateStatus (data) {
  return request.post('/ooh-msp/v2/materialLibrary/updateStatus', qs.stringify(data))
}

/**
 * 创建或更新案例
 * @param {Object} data
 * @returns
 */
export function createOrUpdate (data) {
  return request.post('/ooh-msp/v2/materialLibrary/createOrUpdate', qs.stringify(data))
}

/**
 * 根据id删除播放材料
 * @param {Object} data
 * @returns
 */
export function deleteAttach (data) {
  return request.post('/ooh-msp/v2/materialLibrary/deleteAttach', qs.stringify(data))
}

/**
 * 按行业、产品、站点统计案例数量（1、行业 2、产品 3、站点）
 * @param {Object} data
 * @returns
 */
export function getStatisticList (data) {
  return request.post('/ooh-msp/v2/materialLibrary/getStatisticList', qs.stringify(data))
}

/**
 * 查询已存在的画面文件
 * @param {Object} data
 * @returns
 */
export function getExitFileByFileName (data) {
  return request.post('/ooh-msp/v2/materialLibrary/getExitFileByFileName', qs.stringify(data))
}

/**
 * 删除草稿和下架的素材库
 * @param {Object} data
 * @returns
 */
export function deleteLibraries (data) {
  return request.post('/ooh-msp/v2/materialLibrary/deleteLibraries', qs.stringify(data))
}

/**
 * 更新案例库是否为精选
 * @param {Object} data
 * @returns
 */
export function updateClassicStatus (data) {
  return request.post('/ooh-msp/v2/materialLibrary/updateClassicStatus', qs.stringify(data))
}

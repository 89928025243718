import request from '@/utils/requestV2'
const qs = require('qs')

// 获取实物资产下的站点集合
export function getRimStationByAssetIds (data) {
  return request({
    url: '/ooh-rim/v1/station/getstationbyassetIds',
    method: 'post',
    data: qs.stringify(data),
    timeout: 200000
  })
}
// 获取实物资产下的站点集合
export function getStationByAssetId (data) {
  return request({
    url: '/ooh-rim/v1/station/getstationbyassetId',
    method: 'post',
    data: qs.stringify(data),
    timeout: 200000
  })
}
/**
 * 根据ID获取站点信息
 * @returns
 */
export function getStationById (data) {
  return request.post('/ooh-rim/v1/station/getstationbyid', qs.stringify(data))
}

<style>
.demo-upload-list {
  display: inline-block;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}

.demo-upload-list img {
  width: 100%;
  height: 100%;
}

.demo-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}

.demo-upload-list:hover .demo-upload-list-cover {
  display: block;
}

.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
</style>
<template>
  <div v-if="modifyModal">
    <Modal
      v-model="modifyModal"
      width="680"
    >
      <p
        slot="header"
        class="text-center"
      >{{ newAdd ? '新增' : '编辑' }}案例</p>
      <Form
        :model="formData"
        :rules="ruleValidate"
        ref="formData"
        label-position="top"
        autocomplete="on"
      >
        <Row :gutter="8">
          <i-col span="12">
            <FormItem
              label="名称"
              prop="name"
            >
              <Input
                v-model.trim="formData.name"
                size="small"
                placeholder="请填写案例名称"
              />
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem
              label="所属行业"
              prop="industryId"
            >
              <Select
                v-model="formData.industryId"
                size="small"
                filterable
                placeholder="所属行业，可搜索"
              >
                <Option
                  v-for="item in industryData"
                  :key="'ind_' + item.id"
                  :value="item.id"
                >{{ item.name }}
                </Option>
              </Select>
            </FormItem>
          </i-col>
        </Row>
        <Row :gutter="8">
          <!-- <i-col span="12">
            <FormItem label="所属产品类别" prop="categoryId">
              <Select v-model="formData.categoryId" size="small" filterable placeholder="选择产品类别，可搜索"
                @on-change="onChangeCategory">
                <Option v-for="item in categoryData" :key="'cid_' + item.id" :value="item.id">{{ item.name }}
                </Option>
              </Select>
            </FormItem>
          </i-col> -->
          <i-col span="12">
            <FormItem
              label="所属产品"
              prop="productId"
            >
              <Select
                v-model="formData.productId"
                size="small"
                filterable
                placeholder="选择所属产品，可搜索"
              >
                <Option
                  v-for="item in productData"
                  :key="'pid_' + item.dictKey"
                  :value="Number(item.dictKey)"
                >{{item.dictValue }}</Option>
              </Select>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem
              label="发布档期"
              prop="dateRage"
            >
              <DatePicker
                placeholder="选择发布档期"
                size="small"
                v-model="formData.dateRage"
                type="daterange"
                split-panels
                style="width:100%"
                :editable="false"
                @on-change="onChangeDate"
              ></DatePicker>
            </FormItem>
          </i-col>
        </Row>
        <FormItem
          label="所属资产"
          prop="assetIds"
        >
          <Select
            v-model="formData.assetIds"
            size="small"
            multiple
            filterable
            placeholder="选择所属资产,可多选，可搜索"
            @on-change="onChangeAsset"
          >
            <Option
              v-for="item in assetData"
              :key="'aid_' + item.id"
              :value="item.id"
            >{{ item.name }}</Option>
          </Select>
        </FormItem>

        <FormItem
          label="所属站点"
          prop="stationIds"
        >
          <Select
            v-model="formData.stationIds"
            size="small"
            multiple
            filterable
            :disabled="formData.assetIds.length === 0"
            placeholder="选择所属站点，可多选，可搜索"
          >
            <Option
              v-for="item in stationArray"
              :key="'sid_' + item.id"
              :value="item.id"
            >{{ formData.assetIds.length >1? `${item.name} - ${item.assetName}` : item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label='是否精选'>
          <i-switch v-model="formData.isClassicCase">
            <span slot="open">是</span>
            <span slot="close">否</span>
          </i-switch>
        </FormItem>
        <FormItem label='案例素材上传'>
          <div
            class="demo-upload-list"
            v-for="(item, index) in defaultFiles"
            :key="'default_' + index"
          >
            <video
              v-if="item.mimeType.includes('video') || item.mimeType.includes('audio')"
              :src="item.url"
              width="100"
            >
              您的浏览器不支持 video 标签。
            </video>
            <img
              v-else
              :src="item.url + '?x-oss-process=image/resize,w_100'"
            >
            <div class="demo-upload-list-cover">
              <Icon
                type="ios-eye-outline"
                @click="onViewFile(item.url)"
              ></Icon>
              <Icon
                type="ios-trash-outline"
                @click="onRemoveFile(item)"
              ></Icon>
            </div>
          </div>
          <div
            class="demo-upload-list"
            v-for="(item, index) in uploadFiles"
            :key="'file_' + index"
          >
            <template v-if="item.status === 'finished'">
              <video
                v-if="item.response[0].mimeType.includes('video') || item.response[0].mimeType.includes('audio')"
                :src="item.response[0].filePath"
                width="100"
              >
                您的浏览器不支持 video 标签。
              </video>
              <img :src="item.response[0].filePath + '?x-oss-process=image/resize,w_100'">
              <div class="demo-upload-list-cover">
                <Icon
                  type="ios-eye-outline"
                  @click.native="onViewFile(item.response[0].filePath)"
                ></Icon>
                <Icon
                  type="ios-trash-outline"
                  @click.native="onRemoveFile(item)"
                ></Icon>
              </div>
            </template>
            <template v-else>
              <Progress
                v-if="item.showProgress"
                :percent="item.percentage"
                hide-info
              ></Progress>
            </template>
          </div>
          <Upload
            ref="upload"
            :headers="headers"
            :action="uploadUrl"
            :show-upload-list="false"
            :before-upload="onBeforeUpload"
            :on-progress="onProgress"
            :on-success="onUploadSuccess"
            :format="['jpg', 'jpeg', 'png', 'mp4', 'wmv', 'mov', 'avi', 'mpeg', 'm4v']"
            accept=".jpeg,.jpg,.png,.mp4,.wmv,.mov,.avi,.mpeg,.m4v"
            multiple
            type="drag"
            style="display: inline-block;width:98px;"
          >
            <div style="width: 98px;height:98px;line-height: 98px;">
              <Icon
                type="ios-add"
                size="20"
              ></Icon>
            </div>

          </Upload>
        </FormItem>
        <div class="m-t-10">
          <p class="text-orange">只支持图片和视频文件类型，单个图片大小不超过20M，单个视频大小不超过200M。</p>
          <p class="text-orange">图片上传格式为：jpg、jpeg、 png。</p>
          <p class="text-orange">视频上传格式为：mp4、 wmv、 mov、 avi、 mpeg、m4v。</p>
        </div>
      </Form>
      <div slot="footer">
        <Button
          type="text"
          class="m-r-5"
          @click="onCancel"
        >取消</Button>
        <Button
          type="primary"
          class="m-r-5"
          :loading="btnLoading"
          :disabled="formData.attachIds.length === 0"
          @click="onConfirm(0)"
        >保存为草稿</Button>
        <Button
          type="success"
          class="m-r-5"
          :loading="btnLoading"
          :disabled="formData.attachIds.length === 0"
          @click="onConfirm(1)"
        >保存并上架</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
// import axios from 'axios'
import { getStorage } from '@/utils/storage'
import { caseLibraryMixins } from '../mixins'
import { createOrUpdate, deleteAttach, getExitFileByFileName } from '@/api/msp/materiallibrary'

export default {
  props: {
    newAdd: {
      type: Boolean,
      default: false
    },
    industryData: {
      type: Array,
      required: true,
      default: new Array(0)
    },
    assetData: {
      type: Array,
      required: true,
      default: new Array(0)
    },
    productData: {
      type: Array,
      required: true,
      default: new Array(0)
    }
  },
  mixins: [caseLibraryMixins],
  data () {
    return {
      modifyModal: false,
      uploadUrl: `${process.env.VUE_APP_API_URL_V2}/ooh-msp/v2/materialLibrary/uploadAttach`,
      headers: {
        'ooh-auth': getStorage('ooh-token')
      }, // 上传组件设置协议头
      defaultFiles: [],
      uploadFiles: [],
      btnLoading: false,
      formData: {
        id: undefined,
        name: '',
        attachIds: [],
        assetIds: [],
        stationIds: [],
        industryId: undefined,
        categoryId: undefined,
        productId: undefined,
        startDate: '',
        endDate: '',
        stationInfos: '',
        dateRage: [],
        status: 0,
        isClassicCase: false
      },
      ruleValidate: {
        name: [
          { required: true, type: 'string', message: ' ', trigger: 'blur' }
        ],
        industryId: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        assetIds: [
          { required: true, type: 'array', message: ' ', trigger: 'change' }
        ],
        stationIds: [
          { required: true, type: 'array', message: ' ', trigger: 'change' }
        ],
        // categoryId: [
        //   { required: true, type: 'number', message: ' ', trigger: 'change' }
        // ],
        productId: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        dateRage: [
          { required: true, type: 'array', message: ' ', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    init (data) {
      this.defaultFiles = []
      this.uploadFiles = []
      if (this.newAdd) {
        // this.assetIds = []
        // this.categoryId = undefined
        this.stationArray = []
        this.productArray = []
        Object.assign(this.$data.formData, this.$options.data().formData)
      } else {
        if (data) {
          this.formData.assetIds = []
          this.formData.attachIds = []
          this.formData.id = data.id
          this.formData.name = data.name
          this.formData.industryId = data.industryId
          this.formData.categoryId = data.productCategoryId
          this.formData.productId = data.productId
          this.formData.startDate = data.startDate
          this.formData.endDate = data.endDate
          this.formData.status = data.status
          this.formData.dateRage = [data.startDate, data.endDate]
          this.formData.isClassicCase = data.isClassicCase
          data.stationVOList.forEach(item => {
            if (!this.formData.assetIds.includes(item.assetId)) {
              this.formData.assetIds.push(item.assetId)
            }
          })
          this.formData.stationIds = data.stationVOList.map(x => x.stationId)
          data.attachVOList.forEach(item => {
            const file = Object.assign(item, { name: item.fileName, url: item.filePath })
            this.defaultFiles.push(file)
            // this.uploadFiles.push(file)
            this.formData.attachIds.push(item.id)
          })
          this.onChangeAsset()
          // this.onChangeCategory()
        }
      }
      this.modifyModal = true
    },
    onChangeAsset () {
      if (this.formData.assetIds.length === 0) {
        this.formData.stationIds = []
      }
      const assetIds = JSON.stringify(this.formData.assetIds)
      this.getStationByAssetIds(assetIds)
    },
    // onChangeCategory () {
    //   if (!this.formData.categoryId) {
    //     this.formData.productId = undefined
    //   }
    //   this.getProductByCid(this.formData.categoryId)
    // },
    onChangeDate (date) {
      this.formData.startDate = date[0]
      this.formData.endDate = date[1]
    },
    onBeforeUpload (file) {
      if (file.type.indexOf('image/') > -1 && file.size > 1024 * 1024 * 20) { // 单个文件不超过20M
        this.$Notice.warning({ desc: '文件名为“' + file.name + '”的文件上传失败，单个文件不超过20M' })
        return false
      }
      if (file.type.indexOf('video/') > -1 && file.size > 1024 * 1024 * 200) { // 单个文件不超过200M
        this.$Notice.warning({ desc: '文件名为“' + file.name + '”的文件上传失败，单个文件不超过200M' })
        return false
      }
      this.onCheckExistFile(file)
      return false
    },
    /**
     * 通过上传文件的文件名查询当前上传文件是否已经存在
     * @param {Object} file 上传文件
     */
    onCheckExistFile (file) {
      getExitFileByFileName({ fileName: file.name }).then(res => {
        if (res && !res.errcode && res.length) {
          this.$Modal.confirm({
            title: '上传提示',
            content: `文件名为“${file.name}”的文件已经上传过，确定要再次上传？`,
            onOk: () => {
              this.$refs.upload.post(file)
            }
          })
        } else {
          this.$refs.upload.post(file)
        }
      })
    },
    onProgress (event, file, fileList) {
      this.uploadFiles = fileList
      event.target.onprogress = (e) => {
        const uploadPercent = parseFloat(((e.loaded / e.total) * 100).toFixed(2))
        file.showProgress = true
        file.percentage = uploadPercent
      }
    },
    onUploadSuccess (response) {
      if (response.length && response[0] && response[0].id) {
        this.formData.attachIds.push(response[0].id)
      }
    },
    onViewFile (fileUrl) {
      window.open(fileUrl)
    },
    onRemoveFile (file) {
      let attachId = null
      let isNewUpload = false
      if (file.response && file.response.length) { // 新上传的文件
        attachId = file.response[0].id
        isNewUpload = true
      } else { // 旧文件
        attachId = file.id
      }
      deleteAttach({ attachId }).then(res => {
        if (res && res.errcode === 0) {
          this.$Notice.success({ desc: '删除成功' })
          this.formData.attachIds.splice(this.formData.attachIds.indexOf(attachId), 1)
          if (isNewUpload) { // 移除新上传列表对应数据
            this.uploadFiles.splice(this.uploadFiles.findIndex(x => x.uid === file.uid), 1)
          } else {
            // 移除旧文件对应数据
            this.defaultFiles.splice(this.defaultFiles.findIndex(x => x.id === attachId), 1)
          }
        }
      })
    },
    onCancel () {
      if (this.uploadFiles.length) {
        this.$Modal.confirm({
          title: '操作提示',
          content: '当前存在新上传案例素材，取消保存，新上传案例素材将不会保存，确定要取消？',
          onOk: () => {
            this.uploadFiles = []
            this.modifyModal = false
          }
        })
      } else {
        this.modifyModal = false
      }
    },
    onConfirm (status) {
      this.$refs.formData.validate(valid => {
        if (valid) {
          this.$Modal.confirm({
            title: '操作提示',
            content: `确定要${status === 0 ? '保存为草稿' : '保存并提交'}`,
            onOk: () => {
              this.btnLoading = true
              const selectedStations = this.stationArray.filter(x => this.formData.stationIds.indexOf(x.id) > -1)
              const stationInfos = selectedStations && selectedStations.length ? selectedStations.map(x => {
                return { assetId: x.assetId, assetName: x.assetName, stationId: x.id, stationName: x.name }
              }) : []
              const postData = {
                attachIds: JSON.stringify(this.formData.attachIds),
                industryId: this.formData.industryId,
                name: this.formData.name,
                productId: this.formData.productId,
                startDate: this.formData.startDate,
                endDate: this.formData.endDate,
                status: status,
                stationInfos: JSON.stringify(stationInfos),
                id: this.formData.id,
                isClassicCase: this.formData.isClassicCase
              }
              createOrUpdate(postData).then(res => {
                if (res && res.errcode === 0) {
                  this.$Notice.success({ desc: '操作成功' })
                  this.formData.attachIds = []
                  this.$emit('refreshData')
                }
              }).finally(() => {
                this.btnLoading = false
                this.modifyModal = false
              })
            }
          })
        }
      })
    }
  }
}
</script>

import { listFirstIndustry } from '@/api/dw/platform'
import { allCateory } from '@/api/product/category'
import { getByCategory } from '@/api/product/product'
import { getMaintenanceAssetList } from '@/api/rim/asset'
import { getRimStationByAssetIds } from '@/api/rim/station'
import { dictionary } from '@/api/sys/dictbiz'

export const caseLibraryMixins = {
  data () {
    return {
      statusArray: [
        { value: 0, name: '草稿', color: 'default' },
        { value: 1, name: '已发布', color: 'success' },
        { value: 2, name: '已下架', color: 'error' }
      ],
      industryArray: [],
      categoryArray: [],
      productArray: [],
      assetArray: [],
      stationArray: [],
      statData: [] // 统计数据
    }
  },
  computed: {
    publisherId () {
      return this.$store.getters.token.userInfo.publisherId
    }
  },
  methods: {
    /**
     * 获取一级行业数据
     */
    getIndustryData () {
      this.industryArray = []
      listFirstIndustry({ }).then(res => {
        if (res && !res.errcode) {
          this.industryArray = res
          this.industryArray.forEach(item => {
            item.id = Number(item.id)
          })
        }
      })
    },
    /**
     * 获取所有产品类别
     */
    getCategoryData () {
      this.categoryArray = []
      allCateory().then(res => {
        if (res && !res.errcode) {
          this.categoryArray = res
        }
      })
    },
    /**
     * 根据类别id获取产品数据
     * @param {Number} categoryId 类别id
     */
    getProductByCid (categoryId) {
      this.productArray = []
      if (categoryId) {
        getByCategory({ categoryId }).then(res => {
          if (res && !res.errcode) {
            this.productArray = res
          }
        })
      }
    },
    /**
     * 获取租户下的产品
     */
    getPublisherProductData () {
      this.productArray = []
      dictionary({ code: 'product_demo' }).then(res => {
        if (res && res.success) {
          this.productArray = res.data
        }
      })
    },
    /**
     * 获取资产数据
     */
    getAssetData () {
      this.assetArray = []
      getMaintenanceAssetList().then(res => {
        if (res && !res.errcode) {
          this.assetArray = res
        }
      })
    },
    /**
     * 根据资产id获取站点
     * @param {String} assetIds 资产id集合
     */
    getStationByAssetIds (assetIds) {
      this.stationArray = []
      getRimStationByAssetIds({ assetIds }).then(res => {
        if (res && !res.errcode) {
          this.stationArray = res
        }
      })
    }
  }
}

import request from '@/utils/requestV2'
const qs = require('qs')

// 根据城市CODE获取城市SVG
export function getCitySvg (data) {
  return request({
    url: '/ooh-rim/v1/asset/getcitysvg',
    method: 'post',
    data: qs.stringify(data),
    timeout: 200000
  })
}
// 公司管理设备时，获取当前公司可以查看的资产
export function getMaintenanceAssetList (data) {
  return request({
    url: '/ooh-rim/v1/asset/getmaintenance-assetlist',
    method: 'post',
    data: qs.stringify(data),
    timeout: 200000
  })
}
// 获取租户的实物资产
export function getPublisherAssetList (data) {
  return request({
    url: '/ooh-rim/v1/asset/getpublisher-assetlist',
    method: 'post',
    data: qs.stringify(data),
    timeout: 200000
  })
}

/**
 * 获取资产下的设备类型数。目前分为2级
 * @param {Object} data
 * @returns
 */
export function getDeviceModelList (data) {
  return request.post('/ooh-rim/v1/asset/getdevicemodellist', qs.stringify(data))
}

/**
 * 根据资产获取资产的位置集合
 * @param {Object} data
 * @returns
 */
export function getPositionList (params) {
  return request.get('/ooh-rim/v1/asset/getpositionlist', { params })
}

/**
 * 获取资产下的设备类型数。目前分为2级
 * @param {Object} data
 * @returns
 */
export function getExistDeviceModelList (data) {
  return request.post('/ooh-rim/v1/asset/getexistdevicemodellist', qs.stringify(data))
}

/**
 * 获取公司可用的资产包
 * @returns
 */
export function selectAssetPackage () {
  return request.post('/ooh-rim/v1/asset/selectAssetPackage')
}

/**
 * 根据资产包获取实物资产
 * @returns
 */
export function getPackageAssetList () {
  return request.post('/ooh-rim/v1/asset/getpackage-assetlist')
}

// 获取城市下的所有资产
export function getAllByCityCode (data) {
  return request({
    url: '/ooh-rim/v1/asset/getallbycitycode',
    method: 'post',
    data: qs.stringify(data),
    timeout: 200000
  })
}
/**
 * 根据站点集合获取资产
 * @returns
 */
export function getAssetListByStation (data) {
  return request.post('/ooh-rim/v1/asset/getAssetListByStation', qs.stringify(data))
}
